export const Bio = {
  name: "Amaresh Pati",
  roles: [
    "Full Stack Developer",
    "Mobile App Developer",
    "Devops Engineer",
  ],
  description:
    "I am a skilled and adaptive developer, driven by a passion for tech innovation. With hands-on experience in delivering scalable, high-quality solutions, I approach challenges with a growth mindset and a commitment to impactful results.",
  github: "https://github.com/ptiamaresh13",
  resume:
    "https://drive.google.com/file/d/1ZWDuDlE67LOxNms7R4CWaLV2uSHdvAEW/view?usp=sharing",
  linkedin: "https://www.linkedin.com/in/amaresh-pati-1179b2184/",
  // twitter: "https://twitter.com/RishavChanda",
  insta: "https://www.instagram.com/amaresh_13/",
  // facebook: "https://www.facebook.com/rishav.chanda.165/",
};

export const skills = [
  {
    title: "Application Development",
    skills: [
      { name: "React Native", image: "data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9Ii0xMS41IC0xMC4yMzE3NCAyMyAyMC40NjM0OCI+CiAgPHRpdGxlPlJlYWN0IExvZ288L3RpdGxlPgogIDxjaXJjbGUgY3g9IjAiIGN5PSIwIiByPSIyLjA1IiBmaWxsPSIjNjFkYWZiIi8+CiAgPGcgc3Ryb2tlPSIjNjFkYWZiIiBzdHJva2Utd2lkdGg9IjEiIGZpbGw9Im5vbmUiPgogICAgPGVsbGlwc2Ugcng9IjExIiByeT0iNC4yIi8+CiAgICA8ZWxsaXBzZSByeD0iMTEiIHJ5PSI0LjIiIHRyYW5zZm9ybT0icm90YXRlKDYwKSIvPgogICAgPGVsbGlwc2Ugcng9IjExIiByeT0iNC4yIiB0cmFuc2Zvcm09InJvdGF0ZSgxMjApIi8+CiAgPC9nPgo8L3N2Zz4K" },
      { name: "Flutter", image: "https://cdn.iconscout.com/icon/free/png-256/free-flutter-logo-icon-download-in-svg-png-gif-file-formats--programming-language-coding-development-logos-icons-1720090.png?f=webp" },
      { name: "Android", image: "https://cdn-icons-png.flaticon.com/128/270/270780.png" },
      { name: "iOS", image: "https://cdn3.iconfinder.com/data/icons/social-media-logos-i-filled-line/2048/5315_-_Apple-512.png" },
    ],
  },
  {
    title: "Backend & Database",
    skills: [
      { name: "Node Js", image: "https://cdn-icons-png.flaticon.com/512/5968/5968322.png" },
      { name: "Express Js", image: "https://ajeetchaulagain.com/static/7cb4af597964b0911fe71cb2f8148d64/87351/express-js.png" },
      { name: "MySQL", image: "https://cdn-icons-png.flaticon.com/128/15484/15484291.png" },
      { name: "MongoDB", image: "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcT2ZYtHv2OLXmthRPbkmENZRXuqBVDwlsrZ1A&s" },
      { name: "Firebase", image: "https://firebase.google.com/static/images/brand-guidelines/logo-logomark.png" },
    ],
  },
  {
    title: "DevOps & Cloud",
    skills: [
      { name: "AWS", image: "https://cdn.iconscout.com/icon/free/png-256/free-aws-logo-icon-download-in-svg-png-gif-file-formats--cloud-computing-network-server-database-brand-pack-logos-icons-1583149.png?f=webp&w=256" },
      { name: "Azure", image: "https://www.c-sharpcorner.com/UploadFile/BlogImages/01232023170209PM/Azure%20Icon.png" },
      { name: "Git", image: "https://git-scm.com/images/logos/downloads/Git-Icon-1788C.png" },
      { name: "Google Cloud", image: "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRgFIqWrmOpFaeaJ8plSE6-zr2fxa8OjXL6kg&s" },
      // { name: "Google Play Console", image: "https://play-lh.googleusercontent.com/4_RW0mQ5mJhGGJVydTlQsQ0pnqAYq9UoTVm2_gElrgRM13Q02w43HPgYVaMFy4b4smmF" },
      { name: "Apple App Store", image: "https://upload.wikimedia.org/wikipedia/commons/thumb/6/67/App_Store_%28iOS%29.svg/512px-App_Store_%28iOS%29.svg.png" },
    ],
  },
  {
    title: "Web Development",
    skills: [
      { name: "React Js", image: "data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9Ii0xMS41IC0xMC4yMzE3NCAyMyAyMC40NjM0OCI+CiAgPHRpdGxlPlJlYWN0IExvZ288L3RpdGxlPgogIDxjaXJjbGUgY3g9IjAiIGN5PSIwIiByPSIyLjA1IiBmaWxsPSIjNjFkYWZiIi8+CiAgPGcgc3Ryb2tlPSIjNjFkYWZiIiBzdHJva2Utd2lkdGg9IjEiIGZpbGw9Im5vbmUiPgogICAgPGVsbGlwc2Ugcng9IjExIiByeT0iNC4yIi8+CiAgICA8ZWxsaXBzZSByeD0iMTEiIHJ5PSI0LjIiIHRyYW5zZm9ybT0icm90YXRlKDYwKSIvPgogICAgPGVsbGlwc2Ugcng9IjExIiByeT0iNC4yIiB0cmFuc2Zvcm09InJvdGF0ZSgxMjApIi8+CiAgPC9nPgo8L3N2Zz4K" },
      // { name: "Redux", image: "https://img.icons8.com/color/512/redux.png" },
      { name: "Next Js", image: "https://uxwing.com/wp-content/themes/uxwing/download/brands-and-social-media/nextjs-icon.png" },
      // { name: "HTML", image: "https://cdn-icons-png.flaticon.com/128/1051/1051277.png" },
      // { name: "CSS", image: "https://cdn-icons-png.flaticon.com/128/732/732190.png" },
      // { name: "JavaScript", image: "https://cdn-icons-png.flaticon.com/128/5968/5968292.png" },
      // { name: "Bootstrap", image: "https://cdn-icons-png.flaticon.com/128/5968/5968672.png" },
      { name: "Material UI", image: "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQe5jAcRSDN_ck1Cnivp0JIcRPdJ7-BmkvuAA&s" },
      { name: "Tailwind CSS", image: "https://cdn3d.iconscout.com/3d/free/thumb/free-tailwind-3d-icon-download-in-png-blend-fbx-gltf-file-formats--html-logo-css-framework-customizable-coding-lang-pack-logos-icons-7577995.png" },
    ],
  },
  {
    title: "Operating Systems",
    skills: [
      { name: "Linux", image: "https://cdn-icons-png.flaticon.com/128/518/518713.png" },
      { name: "Windows", image: "https://cdn-icons-png.flaticon.com/128/270/270831.png" },
      { name: "MacOS", image: "https://i.pinimg.com/474x/77/f0/33/77f033d90e28c29dc6ce7006e66d0caa.jpg" },
    ],
  },
  {
    title: "Tools & Others",
    skills: [
      { name: "VS Code", image: "https://cdn-icons-png.flaticon.com/128/906/906324.png" },
      { name: "Postman", image: "https://cdn.iconscout.com/icon/free/png-256/free-postman-logo-icon-download-in-svg-png-gif-file-formats--technology-social-media-company-vol-5-pack-logos-icons-3030217.png?f=webp&w=256" },
      { name: "X Code", image: "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSPE69SFNIWcmqMOVlPvSolhPhLHYVbJkuSiA&s" },
      { name: "Android Studio", image: "https://uxwing.com/wp-content/themes/uxwing/download/brands-and-social-media/android-studio-icon.png" },
    ],
  },
];


// export const experiences = [
//   {
//     id: 0,
//     img: "https://media.licdn.com/dms/image/v2/D560BAQGKj90qdlqDeQ/company-logo_200_200/company-logo_200_200/0/1719599541925/clinohealthinovation_logo?e=1739404800&v=beta&t=5BS-l8_6a0oWPOO-TbsfeMpWAY87X9CMeQv10GUYX8Y", // Add an image URL if available
//     role: "Full-Stack Developer",
//     company: "Clinohealthinnovation Pvt. Ltd.",
//     date: "Sep 2023 – Present",
//     desc: [
//       "Developed and deployed end-to-end web and mobile applications, enhancing customer satisfaction.",
//       "Implemented UI/UX improvements in collaboration with the design team, boosting user experience by 25%.",
//       "Built AWS infrastructure to handle high traffic, managing server loads for over 100,000 users and ensuring secure application scaling",
//       "Led backend development with Express.js, MySQL, and MongoDB, optimizing database operations for enhanced performance."
//     ],
//     skills: [
//       "React Native",
//       "Flutter",
//       "Node.js",
//       "Express.js",
//       "MySQL",
//       "AWS",
//       "MongoDB"
//     ],
//     doc: "", // Add documentation URL if available
//   },
//   {
//     id: 1,
//     img: "https://media.licdn.com/dms/image/v2/D560BAQEk1OWYT1-dRg/company-logo_200_200/company-logo_200_200/0/1719256792463/rkitlimited_logo?e=1739404800&v=beta&t=91TCWn5aCXwin1S7RzW1Gbk0-SjhCRyXRMs8wYZ_efE",
//     role: "Mobile Application Developer",
//     company: "Rechargekit Communication Pvt. Ltd.",
//     date: "May 2022 – Aug 2023",
//     desc: [
//       "Focused on cross-platform mobile development using React Native and Flutter, successfully deploying multiple apps on Google Play and App Store.",
//       "Developed user-centered interfaces with responsive layouts and functional components.",
//       "Integrated Firebase for analytics and notifications, enhancing user engagement.",
//       "Managed code quality using Git version control."
//     ],
//     skills: [
//       "React Native",
//       "Flutter",
//       "Firebase",
//       "Git",
//       "JavaScript",
//       "Android",
//       "iOS"
//     ],
//     doc: "", // Add documentation URL if available
//   },
//   {
//     id: 2,
//     img: "https://media.licdn.com/dms/image/v2/D4D0BAQH7fSgw5WLlEA/company-logo_200_200/company-logo_200_200/0/1692222492008?e=1739404800&v=beta&t=mf7gOLZaEblQOF7iU1LsgPwKEjdWxwYAQnbkSPToGF8",
//     role: "Technical Trainer",
//     company: "Java Technocrat",
//     date: "Sep 2021 – May 2022",
//     desc: [
//       "Conducted training sessions to strengthen programming fundamentals.",
//       "Mentored new developers and provided hands-on guidance in Java and foundational programming concepts."
//     ],
//     skills: [
//       "Java",
//       "Teaching",
//       "JavaScript"
//     ],
//     doc: "", // Add documentation URL if available
//   },
// ];

export const experiences = [
  {
    id: 0,
    img: "https://media.licdn.com/dms/image/v2/D560BAQGKj90qdlqDeQ/company-logo_200_200/company-logo_200_200/0/1719599541925/clinohealthinovation_logo?e=1739404800&v=beta&t=5BS-l8_6a0oWPOO-TbsfeMpWAY87X9CMeQv10GUYX8Y", // Clinohealth Innovation logo URL
    role: "Full-Stack Developer",
    company: "Clinohealth Innovation Pvt. Ltd.",
    date: "Sep 2023 – Present",
    desc: [
      "Created a suite of 4+ mobile apps and 2 web applications, enhancing performance and user satisfaction by 30%.",
      "Developed an Ambulance Booking App, reducing response time by 20%, improving emergency service response.",
      "Built a Doctor Booking App to streamline scheduling, increasing accessibility for users.",
      "Developed an IoT Monitoring App to optimize data tracking, reducing diagnostic errors by 35%.",
      "Managed AWS infrastructure for 100,000+ users, achieving 99.9% uptime, scalability, and security.",
      "Led backend development using Express.js, MySQL, REST APIs, and MongoDB, boosting database performance by 25%.",
      "Deployed 15+ apps on AWS, Google Play Console, and Apple App Store, enhancing deployment efficiency by 40%.",
      "Led a team of 10 developers, improving team productivity and code quality through peer code reviews and pair programming."
    ],
    skills: [
      "React Native",
      "Flutter",
      "Node.js",
      "Express.js",
      "MySQL",
      "AWS",
      "MongoDB",
      "Leadership"
    ],
    doc: "", // Add documentation URL if available
  },
  {
    id: 1,
    img: "https://media.licdn.com/dms/image/v2/D560BAQEk1OWYT1-dRg/company-logo_200_200/company-logo_200_200/0/1719256792463/rkitlimited_logo?e=1739404800&v=beta&t=91TCWn5aCXwin1S7RzW1Gbk0-SjhCRyXRMs8wYZ_efE", // RechargeKit logo URL
    role: "Mobile Application Developer",
    company: "RechargeKit Communication Pvt. Ltd.",
    date: "May 2022 – Aug 2023",
    desc: [
      "Built 8 mobile apps using React Native and Flutter, achieving over 90% ratings on Google Play and App Store.",
      "Developed a Doctor Booking App, increasing booking efficiency by 25% and boosting user retention.",
      "Enhanced e-commerce and inventory management processes, reducing drop-off rates by 18% and improving operational efficiency by 30%.",
      "Implemented audio and video uploading capabilities for media handling, supporting diverse multimedia formats.",
      "Improved UI/UX for cross-device responsiveness, enhancing user engagement.",
      "Integrated Firebase for push notifications and analytics, improving user retention by 10%.",
      "Optimized API performance with GraphQL and Axios, reducing load times by 30%."
    ],
    skills: [
      "React Native",
      "Flutter",
      "Firebase",
      "Git",
      "JavaScript",
      "GraphQL",
      "iOS",
      "Android"
    ],
    doc: "", // Add documentation URL if available
  },
  {
    id: 2,
    img: "https://media.licdn.com/dms/image/v2/D4D0BAQH7fSgw5WLlEA/company-logo_200_200/company-logo_200_200/0/1692222492008?e=1739404800&v=beta&t=mf7gOLZaEblQOF7iU1LsgPwKEjdWxwYAQnbkSPToGF8", // Java Technocrat logo URL
    role: "Technical Trainer",
    company: "Java Technocrat",
    date: "Sep 2021 – May 2022",
    desc: [
      "Conducted 50+ training sessions for 500+ students on Java, Python, and Oracle Database, focusing on practical skills and industry standards.",
      "Delivered 20+ webinars and seminars to simplify complex technical concepts for large audiences."
    ],
    skills: [
      "Java",
      "Python",
      "Oracle Database",
      "Teaching",
      "JavaScript"
    ],
    doc: "", // Add documentation URL if available
  },
];


export const education = [
  {
    id: 0,
    img: "https://media.licdn.com/dms/image/v2/D5603AQE2lBGdPKDgdg/profile-displayphoto-shrink_400_400/profile-displayphoto-shrink_400_400/0/1703747037807?e=1736985600&v=beta&t=wKnke2ILeHgHxeWdL9d1RI0aI7dVPmlGV70Y5ZpDGl8", // Replace with your image URL
    school: "Lakshya Institute of Technology, Bhubaneswar, Odisha",
    date: "Aug 2018 - Sep 2021",
    grade: "N/A",
    desc: "I completed my Bachelor of Science in Computer Science from Lakshya Institute of Technology, Bhubaneswar. During my studies, I gained foundational knowledge in programming, algorithms, data structures, and other essential areas of computer science.",
    degree: "Bachelor of Science in Computer Science",
  }
];

export const projects = [
  {
    id: 0,
    title: "CRM Mobile App",
    description: [
      "Responsible for building the UI, API integration, and business logic.",
      "Features include creating purchase orders, checking stock quantity, and generating invoices.",
      "Supports multiple users with different access levels.",
      "Designed to streamline inventory management and sales operations.",
      "Enables real-time updates on stock and order statuses.",
      "Integrates with existing backend systems for seamless data flow.",
      "Optimized for both Android and iOS platforms using React Native."
    ],
    image: "https://img.freepik.com/free-vector/app-development-banner_33099-1720.jpg",
    tags: ["React Native", "Redux"],
    category: "Mobile app",
    github: "",
    webapp: "",
    overview: "A mobile app for managing orders, stock, and invoices.",
    member: [
      {
        name: "",
        img: "",
        linkedin: "",
        github: "",
      },
    ],
  }, {
    id: 1,
    title: "Ottraxn",
    description: [
      "Responsible for creating the UI and integrating all business logic.",
      "The app allows users to purchase single or bundle OTT subscriptions.",
      "Integrated Razorpay payment gateway for seamless transactions.",
      "Implemented Firebase push notifications for real-time updates.",
      "Gained strong knowledge in Redux for state management.",
      "Designed for a smooth user experience in subscription management.",
      "Supports both individual and bundle subscription purchases."
    ],
    image: "https://img.freepik.com/free-vector/app-development-banner_33099-1720.jpg",
    tags: ["React Native", "Redux", "Razorpay", "Firebase"],
    category: "Mobile app",
    github: "",
    webapp: "",
    overview: "An app for purchasing single or bundle OTT subscriptions.",
    member: [
      {
        name: "",
        img: "",
        linkedin: "",
        github: "",
      },
    ],
  }, {
    id: 2,
    title: "Doctor Appointment Booking App",
    description: [
      "Create user accounts and add patient disease details via audio, video, and images.",
      "Users can select doctors or disease departments and book available date and time slots.",
      "Multiple user types are available: Consultants, Doctors, and Tele-callers.",
      "The app supports multiple languages: Odia, Hindi, Telugu, Tamil, and Bengali.",
      "Auto-detection of OTP for seamless login and account verification.",
      "Also developed the iOS version of the app for broader user reach.",
      "Designed for easy navigation and user-friendly interface for all user types."
    ],
    image: "https://img.freepik.com/free-vector/app-development-banner_33099-1720.jpg",
    tags: ["React Native", "Redux", "Firebase", "iOS", "Graph QL"],
    category: "Mobile app",
    github: "",
    webapp: "",
    overview: "A multilingual app for booking doctor appointments and consultations.",
    member: [
      {
        name: "",
        img: "",
        linkedin: "",
        github: "",
      },
    ],
  }, {
    id: 3,
    title: "Ecommerce App for Farmers and Distributors",
    description: [
      "An app designed to connect farmers and vegetable distributors.",
      "Farmers can list vegetables with bulk pricing and multiple unit options.",
      "Includes all standard e-commerce features for seamless transactions.",
      "Implemented multilingual support to cater to diverse user demographics.",
      "Added text-to-speech functionality for enhanced accessibility.",
      "Optimized for user-friendly navigation and ease of use."
    ],
    image: "https://img.freepik.com/free-vector/app-development-banner_33099-1720.jpg",
    tags: ["React Native", "Text-to-Speech", "Multilingual"],
    category: "Mobile app",
    github: "",
    webapp: "",
    overview: "An e-commerce app connecting farmers and vegetable distributors.",
    member: [
      {
        name: "",
        img: "",
        linkedin: "",
        github: "",
      },
    ],
  }, {
    id: 4,
    title: "Gym Subscription & Inventory Management App",
    description: [
      "Designed for managing gym subscriptions and inventory.",
      "Includes features to track membership plans and inventory items.",
      "Developed using Flutter for both Android and iOS platforms.",
      "Integrated Firebase push notifications for real-time updates.",
      "Optimized for efficient gym operations and inventory tracking."
    ],
    image: "https://img.freepik.com/free-vector/app-development-banner_33099-1720.jpg",
    tags: ["Flutter", "Firebase", "Cross-Platform"],
    category: "Mobile app",
    github: "",
    webapp: "",
    overview: "A gym management app for subscriptions and inventory.",
    member: [
      {
        name: "",
        img: "",
        linkedin: "",
        github: "",
      },
    ],
  }, {
    id: 5,
    title: "PVC Door Inventory Management App",
    description: [
      "Developed an app for managing inventory in a PVC door manufacturing company.",
      "Led the entire project, handling UI/UX, backend development, and app development.",
      "Streamlined inventory tracking and management for improved operational efficiency.",
      "Designed a user-friendly interface for seamless navigation and data access."
    ],
    image: "https://img.freepik.com/free-vector/app-development-banner_33099-1720.jpg",
    tags: ["Flutter", "Backend Development", "Inventory Management"],
    category: "Mobile app",
    github: "",
    webapp: "",
    overview: "An inventory app for PVC door manufacturing operations.",
    member: [
      {
        name: "",
        img: "",
        linkedin: "",
        github: "",
      },
    ],
  }, {
    id: 6,
    title: "Attenify - Attendance Tracking App",
    description: [
      "An app to track attendance using geofencing and WiFi signals.",
      "Built using Flutter for both Android and iOS platforms.",
      "Optimized for accurate and efficient attendance tracking.",
      "Designed for real-time monitoring of attendance data."
    ],
    image: "https://img.freepik.com/free-vector/app-development-banner_33099-1720.jpg",
    tags: ["Flutter", "Geofencing", "Attendance Tracking"],
    category: "Mobile app",
    github: "",
    webapp: "",
    overview: "An app to track attendance with geofencing and WiFi.",
    member: [
      {
        name: "",
        img: "",
        linkedin: "",
        github: "",
      },
    ],
  }, {
    id: 7,
    title: "Cherry Pay - Recharge and Bill Payment App",
    description: [
      "A mobile app for recharges, wallet transfers, DTH, and bill payments.",
      "Developed using React Native with Reanimated for smooth animations.",
      "Integrated Paytm payment gateway for secure transactions.",
      "Optimized for fast and efficient payment processing."
    ],
    image: "https://img.freepik.com/free-vector/app-development-banner_33099-1720.jpg",
    tags: ["React Native", "Reanimated", "Paytm Payment Gateway"],
    category: "Mobile app",
    github: "",
    webapp: "",
    overview: "An app for recharges, wallet transfers, and bill payments.",
    member: [
      {
        name: "",
        img: "",
        linkedin: "",
        github: "",
      },
    ],
  }, {
    id: 8,
    title: "Emi Locke - Smartphone Financing Control App",
    description: [
      "An app designed for smartphone sellers offering self-financing options.",
      "Allows sellers to control sold devices, including locking and stopping network access, when EMIs are unpaid.",
      "Managed a team of 4 React Native developers, 1 Native Android developer, and a backend developer.",
      "Conducted extensive R&D to ensure smooth functionality and robust security.",
      "Integrated Google Zero Touch Portal and Work Profile for seamless device management.",
      "Led the project management, ensuring timely delivery and coordination across all teams."
    ],
    image: "https://acropolium.com/img/articles/find-backend-developers/img01.jpg",
    tags: [
      "React Native",
      "Native Android",
      "Backend Development",
      "Google Zero Touch",
      "Team Management"
    ],
    category: "backend",
    github: "",
    webapp: "",
    overview: "An app for sellers to control financed smartphones remotely.",
    member: [
      {
        name: "",
        img: "",
        linkedin: "",
        github: "",
      },
    ],
  }, {
    id: 9,
    title: "Learning Management System",
    description: [
      "A freelance project focused on managing and creating new curriculums.",
      "Features include curriculum creation, dispatch management, and exam performance tracking.",
      "Developed the entire application, including the backend.",
      "Utilized MongoDB, MySQL, Express.js, and React Native for development.",
      "Designed the system to streamline learning processes and performance analysis."
    ],
    image: "https://img.freepik.com/free-vector/hand-drawn-web-developers_23-2148819604.jpg?semt=ais_hybrid",
    tags: ["MongoDB", "MySQL", "Express.js", "React Native", "Backend Development"],
    category: ["Mobile app", "backend"],
    github: "",
    webapp: "",
    overview: "An app for curriculum management and exam performance tracking.",
    member: [
      {
        name: "",
        img: "",
        linkedin: "",
        github: "",
      },
    ],
  }, {
    id: 10,
    title: "Event Booking App",
    description: [
      "A freelance project designed for booking events seamlessly.",
      "Built using React Native for the app, with Express.js and MS SQL for the backend.",
      "Managed the deployment of the backend on AWS for reliable and scalable performance.",
      "Provided features to browse, book, and manage events effortlessly."
    ],
    image: "https://img.freepik.com/free-vector/hand-drawn-web-developers_23-2148819604.jpg?semt=ais_hybrid",
    tags: ["React Native", "Express.js", "MS SQL", "AWS", "Backend Deployment"],
    category: ["Mobile app", "web app", "backend"],
    github: "",
    webapp: "",
    overview: "An app for seamless event booking and management.",
    member: [
      {
        name: "",
        img: "",
        linkedin: "",
        github: "",
      },
    ],
  }, {
    id: 11,
    title: "Teacher-Student Connection App",
    description: [
      "A freelance project to connect teachers and students seamlessly.",
      "Developed the frontend using React Native for a user-friendly interface.",
      "Used Firebase for backend services, ensuring real-time data synchronization.",
      "The app facilitates efficient communication and collaboration between teachers and students."
    ],
    image: "https://img.freepik.com/free-vector/hand-drawn-web-developers_23-2148819604.jpg?semt=ais_hybrid",
    tags: ["React Native", "Firebase", "Real-Time Communication"],
    category: ["Mobile app", "web app", "backend"],
    github: "",
    webapp: "",
    overview: "An app to connect teachers and students efficiently.",
    member: [
      {
        name: "",
        img: "",
        linkedin: "",
        github: "",
      },
    ],
  }, {
    id: 12,
    title: "IoT Device Connection App",
    description: [
      "An app designed to connect IoT devices with a mobile app and web portal.",
      "Used Express.js to develop the backend and configured the MQTT protocol over AWS.",
      "Implemented WebSocket for real-time data fetching from sensors to the app.",
      "Provides a seamless way to monitor and control IoT sensors through a user-friendly interface."
    ],
    image: "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQLtMS8qiUFvwKVi8C4JrdWm9dOwiZvnwynzA&s",
    tags: ["Express.js", "MQTT", "AWS", "WebSocket", "IoT Integration"],
    category: "backend",
    github: "",
    webapp: "",
    overview: "An app for real-time IoT sensor monitoring and control.",
    member: [
      {
        name: "",
        img: "",
        linkedin: "",
        github: "",
      },
    ],
  }, {
    id: 13,
    title: "IoT Device Sensor & Live Camera App",
    description: [
      "An app designed for IoT devices to fetch and manage sensor values.",
      "Developed and managed both backend and application parts of the project.",
      "Created algorithms to process and manage sensor data efficiently.",
      "Integrated live camera feed functionality for real-time monitoring.",
      "Deployed the app on the Play Store and managed backend deployment on AWS."
    ],
    image: "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQLtMS8qiUFvwKVi8C4JrdWm9dOwiZvnwynzA&s",
    tags: ["IoT", "AWS", "Play Store", "Sensor Data", "Live Camera"],
    category: "backend",
    github: "",
    webapp: "",
    overview: "An app for IoT sensor data and live camera monitoring.",
    member: [
      {
        name: "",
        img: "",
        linkedin: "",
        github: "",
      },
    ],
  }, {
    id: 14,
    title: "Informative Web App",
    description: [
      "A web application built using Next.js to provide information effectively.",
      "Implemented a responsive and visually appealing design using Tailwind CSS.",
      "Focused on delivering a seamless user experience with optimized performance.",
      "The site serves as an informative platform for users, offering structured and easy-to-access content."
    ],
    image: "https://media.istockphoto.com/id/1371339413/photo/co-working-team-meeting-concept-businessman-using-smart-phone-and-digital-tablet-and-laptop.jpg?s=612x612&w=0&k=20&c=ysEsVw3q2axYt3oVZAuQjtHRlN3lY-U_e0ikK5yKIXQ=",
    tags: ["Next.js", "Tailwind CSS", "Web Development"],
    category: "web app",
    github: "",
    webapp: "",
    overview: "A Next.js web app for delivering structured information.",
    member: [
      {
        name: "",
        img: "",
        linkedin: "",
        github: "",
      },
    ],
  },


];

